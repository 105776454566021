import React, { Component } from 'react';
import "../css/Home.css";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Affiches from './Affiches'
import DelayLink from './DelayLink'
// import WeeklyEvents from './WeeklyEvents'


export class Home extends Component {

  state = {
    renderScrollButton: false
  }

  getScrollButtonInfo = (bool) => {
    if (bool) {
      this.setState({ renderScrollButton: true })
    }
  }


  render() {
    const { renderScrollButton } = this.state
    return (
      <div className="Home">
        <div className="home-top">

          <div className="video_container">
            <video autoPlay muted loop controls={false} playsInline>
              <source src={require("../video/bg1.mp4")} type="video/mp4" />
            </video>
          </div>

          <div className="container">
            <div className="logo">
              <img src={require("../img/logo.png")} alt="" />
            </div>
            <h1>Трек для радіокерованих автомоделей у Києві</h1>


            {/* <div className="info">
              <i className="fas fa-exclamation"></i>
              Сезон 2023 завершено <br /> Чекаємо вас у наступному році!
            </div> */}

            <DelayLink
              className="button"
              to="/services"
            >Послуги <span role="img" aria-labelledby="car">🏎️</span></DelayLink>

            {renderScrollButton ?

              <button className="scroll-down">
                <AnchorLink
                  href='#affiches-wrap'
                  duration={500}
                >
                  <i className="fas fa-chevron-down"></i>
                </AnchorLink>
              </button>

              : null}
          </div>
        </div>

        <Affiches getScrollButtonInfo={this.getScrollButtonInfo} />
        {/* <WeeklyEvents /> */}


      </div>
    )
  }
}

export default Home
