import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../../css/Services/RentImages.css'

export class RentImages extends Component {

    state = {
        photoIndex: 0,
        isOpen: false,
    };



  render() {
      const { images, thumbs, video } = this.props
      const { photoIndex, isOpen } = this.state;


    return (
      <div className="RentImages">
        {thumbs.map((image, i)=>{
            return (
                <div key={i} className="event-cell">
                    <div className="event-img-cell">
                        <img 
                            src={image} alt="ecotrack"
                            onClick={() => {
                                this.setState({ isOpen: true })
                                this.setState({ photoIndex: i })
                            }}
                        />
                    </div>
                </div>
            )
        })}
        {video !== null ?
            <div className="event-cell">
                <div className="event-img-cell">
                    <iframe title="ecotrack-video" src={video} allowFullScreen></iframe>
                </div>
            </div>
        :null}
        

        {isOpen && (
            <Lightbox
                imagePadding={20}
                enableZoom={false}
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                this.setState({
                    photoIndex: (photoIndex + images.length - 1) % images.length,
                })
                }
                onMoveNextRequest={() =>
                this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                })
                }
            />
        )}  
      </div>
    )
  }
}

export default RentImages
