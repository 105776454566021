import React, { Component } from 'react'
import "../css/Events.css"
import EventThumb from './EventThumb'
import axios from 'axios'



export class Events extends Component {


  state = {
    events: ''
  }


  componentDidMount(){

    axios.get(`${process.env.REACT_APP_API_PATH}/events?_sort=event_from_date:desc`)
    .then((response) => {

      this.setState({events: response.data})

    })
    .catch((error) => {
      console.log(error);
    })

  }
  

     
   
  render() { 

    const { events } = this.state

    return (
      <div className="Events">
        <div className="container">
            <h1 className="title">Галерея</h1>

            {events !== '' ? 
            
              <div className="events-list">

                  {events.map((item, index)=>{
                      return (

                          <EventThumb
                              key={index}
                              event={item}
                          />
                      )
                      
                  })}
              </div>
              
            : null}

        </div>
      </div>
    )
  }
}

export default Events
