import React, { Component } from 'react'
import '../../css/Services/Celebrate.css'
import DelayLink from '../DelayLink'
import TopSectionServices from './TopSectionServices'
import RentImages from './RentImages'
import axios from 'axios'


export class Celebrate extends Component {

    state = {
        images_1_B: [],
        images_1_S: [],
        images_2_B: [],
        images_2_S: [],
        images_3_S: [],
        images_3_B: [],
        images_4_S: [],
        images_4_B: [],
    }

    componentDidMount() {


        Array.apply(null, Array(4)).forEach((_, index) => {
            axios.get(`${process.env.REACT_APP_API_PATH}/photo-blocks/${index + 1}`)
                .then((response) => {

                    if (response.status === 200) {
                        let images = response.data
                        let images_S = images['photos'].map(i => {
                            return process.env.REACT_APP_API_PATH + i.formats.thumbnail.url
                        })
                        let images_B = images['photos'].map(i => {
                            if (i.formats.large) {
                                return process.env.REACT_APP_API_PATH + i.formats.large.url
                            } else {
                                return process.env.REACT_APP_API_PATH + i.formats.medium.url
                            }
                        })

                        this.setState({ [`images_${index + 1}_B`]: images_B })
                        this.setState({ [`images_${index + 1}_S`]: images_S })
                    }

                })
                .catch((error) => {
                    console.log(error);
                })
        })

    }

    render() {

        const { images_1_B, images_1_S, images_2_B, images_2_S, images_3_S, images_3_B, images_4_B, images_4_S } = this.state

        return (
            <div className="Celebrate">

                <button className="back-btn">
                    <DelayLink to='/services'>
                        <i className="fas fa-angle-left"></i>
                        <span>послуги</span>
                    </DelayLink>
                </button>
                <TopSectionServices img={'hero4.jpg'} title="Дні народження та корпоративи" />

                <div className="container">
                    <div className="celebrate-block">

                        <div className="celebrate-block-intro">
                            Трек радіокерованих автомоделей пропонує вам відсвяткувати свій івент або День Народження у форматі міні-чемпіонату з автомодельного спорту. Це буде цікаво та пізнавально як дорослим, так і хлопцям та дівчатам від 8 років. У заїздах надаються спортивні автомоделі класу Short Course, швидкість яких досягає 30 км/год. Перегони супроводжує та коментує професійний суддя-коментатор. Все це дозволить вам відчути справжній адреналін та зануритися у процес перегонів. Наприкінці перегонів відбувається нагородження переможців заїздів на п'єдесталі. Переможці змагань отримують фірмові призи від Eco Track. Додатково до міні-чемпіонату пропонуємо квест. Також можливе технічне забезпечення та супровід вашого івенту ведучим, ді-джеєм чи запрошеним гуртом
                        </div>

                        <div className="celebrate-offer">
                            <div className="offer-main">
                                <h2 className="offer-title">
                                    Міні-чемпіонат
                                </h2>

                                <div className="offer-description">

                                    <p className="mb">У міні чемпіонаті можуть брати участь від 4 до 30 осіб. Тривалість змагань залежить від кількості учасників і може коливатись від 1 до 4 годин. Перед участю кожен учасник проходить інструктаж, базове навчання та тренувальні заїзди</p>

                                    <p className="mb">Проведення змагань у одному з форматів:</p>

                                    <p><span>1. </span>Стандартна система</p>
                                    <p className="mb">За результатом кваліфікації, пілоти розподіляються на групи свого рівня (фінал А, B, C ...). Кожна група проїжджає однакову кількість заїздів, за результатами яких у кожній групі визначається свій переможець</p>
                                    <p><span>2. </span>Кубкова система</p>
                                    <p className="mb">Розподілення на групи пілотів. Найшвидші пілоти з кожної групи проходять далі по кубковій решітці, доходячи до пів-фіналу та фіналу.</p>
                                    <p>Вартість міні-чемпіонату залежить від кількості учасників та складає від <span>550грн</span> до <span>700грн</span> за учасника, не враховуючи призи переможцям</p>
                                </div>
                            </div>
                            <div className="offer-photos">
                                <RentImages
                                    key={2}
                                    images={images_2_B}
                                    thumbs={images_2_S}
                                    video={null}
                                />
                            </div>
                        </div>

                        <div className="celebrate-offer">
                            <div className="offer-main">
                                <h2 className="offer-title">
                                    Квест
                                </h2>

                                <div className="offer-description">

                                    <p className="mb">Квест складається із 10 етапів і відбувається на території Eco Track. Тривалість квесту приблизно 40 хвилин. Кількість учасників до 10 осіб. Фінал квесту - приз (напої на вибір). </p>
                                    <p>Вартість квесту - <span>1000грн</span></p>

                                </div>
                            </div>
                            <div className="offer-photos">
                                <RentImages
                                    key={3}
                                    images={images_3_B}
                                    thumbs={images_3_S}
                                    video={null}
                                />
                            </div>
                        </div>


                        <div className="celebrate-offer">
                            <div className="offer-main">
                                <h2 className="offer-title">
                                    Технічне забезпечення івенту
                                </h2>

                                <div className="offer-description">

                                    <p className="mb">Пропонуємо повний комплекс технічного забезпечення звуковим, світловим, сценічним та відео обладнанням будь-якої складності.</p>

                                </div>
                            </div>
                            <div className="offer-photos">
                                <RentImages
                                    key={4}
                                    images={images_4_B}
                                    thumbs={images_4_S}
                                    video={null}
                                />
                            </div>
                        </div>

                        <div className="celebrate-offer">
                            <div className="offer-main">
                                <h2 className="offer-title">
                                    Зона відпочинку
                                </h2>

                                <div className="offer-description">
                                    <p>Ми пропонуємо місце для вашого івенту, яке включає в себе:</p>
                                    <ol>
                                        <li>Намет з посадочними місцями до 20 осіб</li>
                                        <li>Круглий стіл</li>
                                        <li>2 парасолі, 8 шезлонгів</li>
                                        <li>Холодильник для напоїв, чайник</li>
                                        <li>Мангал</li>
                                        <li>Доступ до електрики</li>
                                    </ol>
                                    <p>Вартість зони відпочинку:</p>
                                    <p>Будні - <span>1200грн</span> (до 22:00, після - <span>600грн</span> за кожну годину)</p>
                                    <p>Вихідні та свята - <span>2000грн</span> (до 22:00, після - <span>1000грн</span> за кожну годину)</p>
                                </div>
                            </div>
                            <div className="offer-photos">
                                <RentImages
                                    key={1}
                                    images={images_1_B}
                                    thumbs={images_1_S}
                                    video={null}
                                />
                            </div>
                        </div>

                        <div className="celebrate-offer">
                            <div className="offer-main">
                                <h2 className="offer-title">
                                    Персональні запрошення
                                </h2>

                                <div className="offer-description">
                                    <p>Бажаєте запросити своїх друзів або колег на міні-чемпіонат? Eco Track пропонує фірмові запрошення.</p>
                                </div>
                            </div>
                            <div className="offer-photos">
                                {/* <RentImages
                                    key = {1}
                                    images = {images_1_B}
                                    thumbs = {images_1_S}
                                    video = {null}
                                /> */}
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        )
    }
}

export default Celebrate
