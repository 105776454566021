import React from 'react'
import { Link } from 'react-router-dom'
import video from "../video/bg1.mp4"
import '../css/WrongPage.css'

export default function WrongPage() {
  return (
    <div className="error-page">

        <h2>404</h2>
        <h3>Сторінки не існує</h3>

          <Link to="/"><span>на головну</span></Link>

        <div className="video_container">
            <video autoPlay muted loop controls={false} playsInline>
                <source src={require("../video/bg1.mp4")} type="video/mp4" />
            </video>          
        </div>

    </div>
  )
}
