import React, { Component } from 'react'
import '../../css/Booking/Booking.css'
import Day from './Day'

export class Booking extends Component {


    state = {
        bookings: []
    }




    componentDidMount(){            
        
        fetch('https://www.googleapis.com/calendar/v3/calendars/s79s642m6jfvec6lk538mnvvf4@group.calendar.google.com/events?key=AIzaSyApWEHFMNTQVYkc510MAfgXSMjpM6flKAY')
        .then(response => response.json())
        .then(data => {
            let nowMs = Math.floor(new Date().getTime());

            let bookings = Array.from({length: 8}, (_,i)=>{

                
                let weekday = this.getWeekDay(new Date(nowMs + (i*86400000)))

                let date = this.getDate(new Date(nowMs + (i*86400000))).length === 1 ? `0${this.getDate(new Date(nowMs + (i*86400000)))}` : this.getDate(new Date(nowMs + (i*86400000)))

                
                let month = this.getMonth(new Date(nowMs + (i*86400000)))

                return {
                    day: weekday,
                    date: date,
                    month: month,
                    list: []
                }
            })

            let sortedData = data.items.sort((a,b)=>{
                return Math.floor(new Date(a.start.dateTime).getTime() - Math.floor(new Date(b.start.dateTime).getTime()))
            });

            
            let now = new Date();
            const _ms_per_day = 1000 * 60 * 60 * 24;

            sortedData.forEach(j=>{

                let eventDayCount = new Date(j.start.dateTime)

                const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
                const utc2 = Date.UTC(eventDayCount.getFullYear(), eventDayCount.getMonth(), eventDayCount.getDate());


                let dayDelta = Math.floor((utc2 - utc1) / _ms_per_day);

                if (dayDelta >= 0 && dayDelta <=7){
                    bookings[dayDelta].list.push(j)
                    
                }

            })

            
            this.setState({bookings})

        })
        .catch(err => {
            console.log('Fetch Error ', err);
        });

    }


    getMonth = (date) => {
        let months = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
        return months[new Date(date).getMonth()]
    }
    getWeekDay = (date) => {
        let weekdays = ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', `П'ятниця`, 'Субота'];
        return weekdays[new Date(date).getDay()]
    }

    getDate = (date) => {
        return new Date(date).getDate()
    }



  render() {
    const { bookings } = this.state

    return (
      <div className="Booking">
        <div className="container">
            <h2 className="title">Бронювання</h2>
            <div className="days-list">
                {bookings.length !== 0 ? bookings.map((day, i)=>{

                    return <Day key={i} dayInfo={day}/>

                }): 
                    <img
                        className="preloader"
                        src={require("../../img/preloader.gif")}
                        alt=""
                    />
                    
                }
            
            </div>
            
        </div>
      </div>
    )
  }
}

export default Booking
