import React, { Component } from 'react'
import '../../css/Services/Certificate.css'
import DelayLink from '../DelayLink'
import TopSectionServices from './TopSectionServices'


export class Certificate extends Component {
    render() {
        return (
            <div className="Certificate">
                <button className="back-btn">
                    <DelayLink to='/services'>
                        <i className="fas fa-angle-left"></i>
                        <span>послуги</span>
                    </DelayLink>
                </button>

                <TopSectionServices img={'hero4.jpg'} title="Подарункові сертифікати" />

                <div className="container">

                    <div className="price-table-wrap">
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/cert1.jpg')} alt="" />
                                <p>Сертифікат на 1 заїзд</p>
                            </div>
                            <div className="cell cell-price cell-price-value yellow">250 <span>₴</span></div>
                        </div>
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/cert1.jpg')} alt="" />
                                <p>Сертифікат на 2 заїзди</p>
                            </div>
                            <div className="cell cell-price cell-price-value yellow">500 <span>₴</span></div>
                        </div>
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/cert1.jpg')} alt="" />
                                <p>Сертифікат на 4 заїзди</p>
                            </div>
                            <div className="cell cell-price cell-price-value yellow">1000 <span>₴</span></div>
                        </div>
                    </div>

                    <p className="disclamer">
                        Сертифікати придбати можливо в нас на треку
                    </p>

                </div>
            </div>
        )
    }
}

export default Certificate
