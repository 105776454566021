import React, { Component } from 'react'
import '../../css/Booking/Day.css'

export class Day extends Component {


    getMonth = (date) => {
        let months = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
        return months[new Date(date).getMonth()]
    }
    getWeekDay = (date) => {
        let weekdays = ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', `П'ятниця`, 'Субота'];
        return weekdays[new Date(date).getDay()]
    }

    getDate = (date) => {
        return new Date(date).getDate()
    }

    getHours = (date) => {
        return new Date(date).getHours()
    }
    getMinutes = (date) => {
        if (new Date(date).getMinutes() === 0){
            return `${new Date(date).getMinutes()}0`
        } else {
            return new Date(date).getMinutes()
        }
    }

  render() {
    if (this.props.dayInfo){
        const {day, date, month, list} = this.props.dayInfo
        return (
          <div className="Day">
            <div className="day-head">
                <div className="day-main-title">{day}</div>
                <div className="day-main-date">{`${month}, ${date}`}</div>
            </div>
            <div className="day-body">
                
                {list.length !== 0 ? list.map((event, index)=> {
                    let eventStart = event.start.dateTime
                    let eventEnd = event.end.dateTime
                    return (
                        <div key={index} className="day-event">
                            <div className="day-event-time">
                                {` ${this.getHours(eventStart)}:${this.getMinutes(eventStart)} - ${this.getHours(eventEnd)}:${this.getMinutes(eventEnd)}`}
                            </div>
                            <div className="cars-avaliable">
                                <p>заброньовано моделей</p>
                                <p>{event.summary ? parseInt(event.summary) : 6 }&nbsp;<span>з</span>&nbsp;6</p>
                            </div>
                        </div>
                    )
                }) : <div className="day-free">Бронювань на цей день поки що немає</div>}
            </div>
          </div>
        )

    }else {
        return <div />
    }
  }
}

export default Day
