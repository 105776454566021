import React, { Component } from 'react'
import ServiceCard from './ServiceCard'
import services from '../../services.json'
import '../../css/Services/Services.css'

export class Services extends Component {
    render() {
        return (
            <div className="Services">
                <div className="container">
                    <h1 className="title">Послуги</h1>

                    <div className="services-list">
                        {services.map((service, index) => {
                            if (!service.disable) {
                                return (
                                    <ServiceCard
                                        key={index}
                                        service={service}
                                    />
                                )
                            } else return null
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default Services
