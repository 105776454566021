import React, { Component } from 'react'
import '../../css/WeekDriver/WeekDriver.css'
import TopSectionServices from '../Services/TopSectionServices'
import RaceItem from '../WeekDriver/RaceItem'
import axios from 'axios'


export class WeekDriver extends Component {

    state = {
        pastRaces: [],
        nextRace: null

    }

    componentDidMount() {

        axios.get(`${process.env.REACT_APP_API_PATH}/week-drive-races/?_sort=race_date:DESC`)
            .then((response) => {
                this.getNextRace(response.data)
                this.getPastRaces(response.data)
            })
            .catch((error) => {
                console.log(error);
            })

    }



    getPastRaces = (races) => {
        let now = new Date().setHours(0, 0, 0, 0)
        let pastRaces = []

        races.forEach(race => {
            let raceDate = new Date(race.race_date).setHours(0, 0, 0, 0)

            if (raceDate < now) {

                if (
                    race.driver_name_1 !== '' &&
                    race.driver_name_2 !== '' &&
                    race.driver_name_3 !== ''
                ) {
                    pastRaces.push(race)
                }
            }
        })

        this.setState({ pastRaces })
    }

    getNextRace = (races) => {

        let now = new Date().setHours(0, 0, 0, 0)
        let nextRace = null

        races.forEach(race => {
            let raceDate = new Date(race.race_date).setHours(0, 0, 0, 0)

            if (
                !nextRace &&
                new Date(race.race_date).setHours(0, 0, 0, 0) >= now

            ) {
                nextRace = race
            } else if (
                raceDate >= now &&
                raceDate < new Date(nextRace.race_date).setHours(0, 0, 0, 0)
            ) {
                nextRace = race
            }
        })

        if (nextRace) {

            this.setState({ nextRace })
        }

    }

    formateDate = (date) => {
        date = new Date(date)
        let d = `${date.getDate()}`.length > 1 ? date.getDate() : `0${date.getDate()}`
        let m = `${date.getMonth() + 1}`.length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
        let y = date.getFullYear()

        return (`${d}.${m}.${y}`)
    }


    render() {

        const { pastRaces, nextRace } = this.state

        return (
            <div className="WeekDriver">
                <TopSectionServices img={'hero5.jpg'} title="WeekDriver" />
                <div className="container">

                    <div className="week-driver-heading">
                        Щотижневі перегони на професійних автомоделях Team Assosiated Pro4 SC10
                    </div>

                    <div className="week-driver-info">

                        <p>WeekDriver - формат гонки для досвідчених пілотів. Якщо ти любиш швидкість і драйв - ласкаво просимо на WeekDriver. Це унікальна можливість спробувати себе в ролі професійного пілота на шляху до RC спорту.</p>

                        <p>Взяти участь може кожен бажаючий, чий час проходження кола <span>менше 45 секунд</span>  на прокатних моделях.</p>

                        <p>Пілоти змагаються на професійних моделях Short Course Associated Pro4 SC10.</p>

                        <p>
                            Формат гонки: <br />
                            1. Тренувальний заїзд тривалістю 3 кола <br />
                            2. Кваліфікаційний заїзд тривалістю 5 хвилин <br />
                            3. Три фінальних заїзди по 3 хвилини <br />
                        </p>

                        <p>WeekDriver проводитися <span>щотиждня</span>  (при відсутності дощу).</p>

                        <p>Дату на час проведення уточнюйте у адміністратора.</p>

                        <p>Мінімальна кількість учасників - <span>8</span>. Максимальна - <span>16</span>.</p>

                        <p>Переможець гонки отримує <span>фірмовий приз</span> від Eco Track, а всі призери - <span>фірмові медалі</span>.</p>

                        <p>Вартість участі: <span>400грн</span></p>

                        <p>Запис на гонку по телефону: <a href="tel:+380674029302"><span>+380674029302</span></a> або <a href="tel:+380638683864"><span>+380638683864</span></a></p>
                    </div>

                    {nextRace ?
                        <div className="week-driver-next-race">
                            <div className="next-race-heading">
                                Наступна гонка
                            </div>
                            <div className="next-race-main">
                                <p>
                                    {nextRace.race_name} <span>{this.formateDate(nextRace.race_date)}</span>
                                </p>
                                {nextRace.race_detailed_url ?

                                    <a
                                        target="_blank"
                                        href={nextRace.race_detailed_url}
                                        rel="noopener noreferrer"
                                    >
                                        детальніше
                                    </a>

                                    : null}
                            </div>

                        </div>
                        : null}




                    {pastRaces.length > 0 ?

                        <div className="week-driver-past-races">
                            <div className="past-races-title">
                                Минулі гонки
                            </div>

                            <div className="past-races-list">
                                {pastRaces.map((race, index) => {
                                    return (
                                        <RaceItem
                                            key={index}
                                            race={race}
                                        />
                                    )
                                })}


                            </div>

                        </div>
                        : null}



                </div>
            </div>
        )
    }
}

export default WeekDriver
