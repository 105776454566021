import React, { Component } from 'react';
import '../../css/Services/TopSectionServices.css'

class TopSectionServices extends Component {

    state = {
        scrollTop: 0
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollListen)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListen)
    }

    scrollListen = () => {
        this.setState({ scrollTop: window.scrollY })
    }

    render() {
        const { title, img } = this.props
        const { scrollTop } = this.state
        return (
            <div className="TopSectionServices" style={{ backgroundImage: `url(${require('../../img/services/' + img)}`, backgroundPosition: `center ${50 - (scrollTop / 6)}%` }}>
                <h1 className="section-title">
                    {title}
                </h1>
            </div>
        );
    }
}

export default TopSectionServices;
