import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import '../css/Slider.css'
import eco from '../img/spinner.svg'

export class Slider extends Component {


  componentDidMount(){
      setTimeout(() => {
          setTimeout(() => {
            this.props.dispatch({type: "SLIDER", payload: false})
          }, 200);
          this.props.dispatch({type: "SPINNER", payload: false})
      }, 200);
  }
    
  render() {
    const { children, slider, spinner } = this.props
      return (
        <Fragment>
            <div className={slider ? "slider-active slider" : "slider"}>
                <div className={spinner ? "slider-spinner-wrap slider-spinner-wrap-active" : "slider-spinner-wrap"}>
                    <img src={eco} alt=""/>
                </div>
            </div>
            <div className="content">
              {children}
            </div>
        </Fragment>
      );

    
  
  }
}
    
export default withRouter(Slider);