import React, { Component } from 'react'
import '../../css/Services/Train.css'
import DelayLink from '../DelayLink'
import TopSectionServices from './TopSectionServices'

export class Train extends Component {
    render() {
        return (
            <div className="Train">
                <button className="back-btn">
                    <DelayLink to='/services'>
                        <i className="fas fa-angle-left"></i>
                        <span>послуги</span>
                    </DelayLink>
                </button>
                <TopSectionServices img={'hero2.jpg'} title="Тренування з власною автомоделлю" />

                <div className="container">

                    <h2>Маєш власну автомодель? Запрошуємо тебе на тренування!</h2>

                    <p className="disclamer">
                        Тренування на треку дозволено власникам моделей масштабом 1/10 та менше
                    </p>

                    <div className="price-table-wrap">
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/train/train1.jpg')} alt="" />
                                <p>День</p>
                            </div>
                            <div className="cell cell-price cell-price-value yellow">400 <span>₴</span></div>
                            <div className="cell cell-benefits gray">
                                <div className="list-title">
                                    Включено
                                </div>
                                <div className="list-values">
                                    <div className="benefit-item">Доступ до електрики</div>
                                    <div className="benefit-item">Робоче місце</div>
                                    <div className="benefit-item">Тренування із системою фіксації часу</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/train/train2.jpg')} alt="" />
                                <p>Місяць</p>
                            </div>
                            <div className="cell cell-price cell-price-value yellow">1300 <span>₴</span></div>
                            <div className="cell cell-benefits gray">
                                <div className="list-title">
                                    Включено
                                </div>
                                <div className="list-values">
                                    <div className="benefit-item">Доступ до електрики</div>
                                    <div className="benefit-item">Робоче місце</div>
                                    <div className="benefit-item">Тренування із системою фіксації часу</div>
                                    <div className="benefit-item">Безкоштовна учаcть у клубних заходах</div>
                                    <div className="benefit-item">Безкоштовне зберігання моделей</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/train/train3.jpg')} alt="" />
                                <p>Сезон</p>
                            </div>
                            <div className="cell cell-price cell-price-value yellow">6500 <span>₴</span></div>
                            <div className="cell cell-benefits gray">
                                <div className="list-title">
                                    Включено
                                </div>
                                <div className="list-values">
                                    <div className="benefit-item">Доступ до електрики</div>
                                    <div className="benefit-item">Робоче місце</div>
                                    <div className="benefit-item">Тренування із системою фіксації часу</div>
                                    <div className="benefit-item">Безкоштовна учаcть у клубних заходах</div>
                                    <div className="benefit-item">Безкоштовне зберігання моделей</div>
                                    <div className="benefit-item">Пріоритетне місце</div>
                                    <div className="benefit-item">Знижка 10% на клубні змагання</div>
                                    <div className="benefit-item">Знижка 10% на проведення власних заходів</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <p className="disclamer">
                        Дітям до 12 років безкоштовне відвідування треку зі своєю автомоделлю
                    </p>
                    <p className="disclamer">
                        Дітям від 12 до 18 років знижка 50% на відвідування треку зі своєю автомоделлю
                    </p>


                    <h2 style={{ marginTop: '70px' }}>Пропонуємо фіксовану кількість тренувальних днів зі знижкою</h2>

                    <div className="price-table-wrap">
                        <div className="row no-gap">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/train/train4.jpg')} alt="" />
                                <p>5 тренувань</p>
                            </div>
                            <div className="cell cell-price cell-price-value yellow">1700 <span>₴</span></div>
                        </div>
                        <div className="row no-gap">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/train/train4.jpg')} alt="" />
                                <p>10 тренувань</p>
                            </div>
                            <div className="cell cell-price cell-price-value yellow">3400 <span>₴</span></div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export default Train
