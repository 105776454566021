import React, { Component } from 'react'
import DelayLink from './DelayLink'
import '../css/EventThumb.css'

export class EventThumb extends Component {

    state = {
        imageDidLoad: false
    }

    formateDate = (date) => {
        date = new Date(date)
        let d = `${date.getDate()}`.length > 1 ? date.getDate() : `0${date.getDate()}`
        let m = `${date.getMonth()+1}`.length > 1 ? date.getMonth()+1 : `0${date.getMonth()+1}`
        let y = date.getFullYear()

        return (`${d}.${m}.${y}`)
    }

    render() {
        const { imageDidLoad } = this.state
        const { event_thumbnail, event_title, event_from_date, event_to_date, slug } = this.props.event
        
        let thumb = event_thumbnail ? process.env.REACT_APP_API_PATH + event_thumbnail.formats.medium.url : null

        let title = event_title

        let date = event_from_date === event_to_date ? this.formateDate(event_from_date) : `${this.formateDate(event_from_date)} - ${this.formateDate(event_to_date)}`

        return (
            <div className="EventThumb">
                <div className="event_thumb-box">
                    <DelayLink
                        to={`/gallery/${slug}`}
                    >

                        {!imageDidLoad ? <img
                            className="preloader"
                            src={require("../img/preloader.gif")}
                            alt=""
                        />
                        :null}
                        
                        <img
                            className={imageDidLoad ? "event_thumb-box-img" : "event_thumb-box-img-loading" }
                            src={thumb} 
                            alt=""
                            onLoad={()=>{
                                this.setState({imageDidLoad: true})
                            }}
                        />

                        <div className="event_thumb-title">
                            {title}
                        </div>
                        <div className="event_thumb-date">
                            {date}
                        </div>
                    
                    </DelayLink>
                </div>
            </div>
        )
    }
}

export default EventThumb
