import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Consumer } from '../context'


export class DelayLink extends React.Component {


  handleClick = (dispatch, e) => {
    const { to, delay, navToggle, history } = this.props;

    let defaulDelay = 500

    e.preventDefault()

    setTimeout(() => {
        dispatch({type: "SPINNER", payload: true})
        if (navToggle) {
            navToggle()
        }
    }, 200);

    dispatch({type: "SLIDER", payload: true})

    setTimeout(() => {
        history.push(to);

        setTimeout(() => {
            dispatch({type:"SLIDER", payload: false})
        }, 200);
        
        dispatch({type: "SPINNER", payload: false})
    }, delay || defaulDelay);
  };

  render() {
    const props = Object.assign({}, this.props);
    delete props.delay;
    delete props.onDelayStart;
    delete props.onDelayEnd;
    delete props.staticContext;
    delete props.dispatch;
    delete props.navToggle;
    delete props.history;
    delete props.match;
    delete props.location;
    
    return (
      <Consumer>
        {value => {
          return(

            <Link {...props} onClick={(e)=>this.handleClick(value.dispatch, e)} />
          )
        }}
      </Consumer>
    );
  }
}

export default withRouter(DelayLink)