import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './css/Global.css';
import TagManager from 'react-gtm-module'
import Header from './components/Header'
import Home from './components/Home'
import WrongPage from './components/WrongPage'
import About from './components/About'
import Events from './components/Events'
import EventDetail from './components/EventDetail'
import Booking from './components/Booking/Booking'
import Contacts from './components/Contacts'
import ScrollTop from './components/ScrollTop'
import Services from './components/Services/Services'
import Rent from './components/Services/Rent'
import Price from './components/Services/Price'
import Train from './components/Services/Train'
import Certificate from './components/Services/Certificate'
import Celebrate from './components/Services/Celebrate'
import Footer from './components/Footer'
import Grid from './components/Grid'
import Slider from './components/Slider'
import WeekDriver from './components/WeekDriver/WeekDriver'
import AOS from 'aos'
import { Provider, Consumer } from './context'
import 'aos/dist/aos.css';



class App extends Component {

  componentDidMount() {
    AOS.init({
      offset: 100,
      duration: 700
    })

    const tagManagerArgs = {
      gtmId: 'GTM-K5W923G'
    };

    TagManager.initialize(tagManagerArgs)

  }

  render() {
    return (
      <div className="App">
        <Provider>
          <Consumer>
            {value => {
              const { slider, spinner, dispatch } = value
              return (
                <Router>
                  <ScrollTop>

                    <Header
                      dispatch={dispatch}
                      spinner={spinner}
                      slider={slider}
                    />
                    <Slider
                      slider={slider}
                      dispatch={dispatch}
                      spinner={spinner}

                    >
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/services" component={Services} />
                        <Route exact path="/gallery" component={Events} />
                        <Route exact path="/gallery/:event" component={EventDetail} />
                        <Route exact path="/booking" component={Booking} />
                        <Route exact path="/contacts" component={Contacts} />
                        <Route exact path="/grid" component={Grid} />
                        <Route exact path="/services/outbound" component={Rent} />
                        <Route exact path="/services/rent" component={Price} />
                        <Route exact path="/services/training" component={Train} />
                        <Route exact path="/services/certificate" component={Certificate} />
                        <Route exact path="/services/celebrate" component={Celebrate} />
                        <Route exact path="/weekdriver" component={WeekDriver} />
                        <Route component={WrongPage} />
                      </Switch>
                    </Slider>
                    <Footer />
                  </ScrollTop>
                </Router>
              )
            }}
          </Consumer>
        </Provider>



      </div>
    );
  }
}

export default App;
