import React from 'react'
import DelayLink from './DelayLink'
import '../css/Footer.css'

export default function Footer() {
    const year = new Date().getFullYear()
    return (
        <div className="Footer">
            <div className="footer-main">
                <div className="container">
                    <div className="footer-nav">
                        <div className="footer-nav-item-wrap">
                            <DelayLink 
                                className="footer-nav-item"
                                to="/"
                            >Головна</DelayLink>
                        </div>
                        <div className="footer-nav-item-wrap">
                            <DelayLink 
                                className="footer-nav-item"
                                to="/about"
                            >Про трек</DelayLink>
                        </div>
                        <div className="footer-nav-item-wrap">
                            <DelayLink 
                                className="footer-nav-item"
                                to="/services"
                            >Послуги</DelayLink>
                        </div>
                        <div className="footer-nav-item-wrap">
                            <DelayLink 
                                className="footer-nav-item"
                                to="/gallery"
                            >Галерея</DelayLink>
                        </div>
                        <div className="footer-nav-item-wrap">
                            <DelayLink 
                                className="footer-nav-item"
                                to="/booking"
                            >Бронювання</DelayLink>
                        </div>
                        <div className="footer-nav-item-wrap">
                            <DelayLink 
                                className="footer-nav-item"
                                to="/contacts"
                            >Контакти</DelayLink>
                        </div>
                        <div className="footer-nav-item-wrap">
                            <DelayLink 
                                className="footer-nav-item"
                                to="/grid"
                            >Генератор турнірів</DelayLink>
                        </div>
                        
                    </div>

                    <div className="footer-info">
                        <div className="footer-info-social">
                            <div className="footer-social-elem">
                                <a
                                    className="contacts-social-link"
                                    target="_blank" 
                                    href="https://www.facebook.com/ECOTrackRC/"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-facebook"></i>
                                </a>
                            
                            </div>
                            <div className="footer-social-elem">
                                <a
                                    className="contacts-social-link"
                                    target="_blank" 
                                    href="https://www.instagram.com/ecotrack.rc/"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </div>
                            <div className="footer-social-elem">
                                <a
                                    className="contacts-social-link"
                                    target="_blank" 
                                    href="https://www.youtube.com/channel/UCGoSQGhbuT0sqb2nsPoFEHg"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-extra">
                <p>{`© 2017-${year} Eco Track`}</p>
            </div>
        </div>
    )
}
