import React, { Component } from 'react'
import '../css/Contacts.css'

export class Contacts extends Component {
    render() {
        return (
            <div className="Contacts">
                <div className="container">
                    <div className="contacts-main">

                        <div className="contacts-worktime">
                            <div className="contacts-worktime-title">
                                Режим роботи
                            </div>

                            <div className="contacts-worktime-value">
                                <div className="contacts-worktime-day">
                                    <span>
                                        Вівторок - Неділя
                                    </span>
                                    <span>
                                        (у понеділок трек не працює)
                                    </span>
                                </div>
                                <div className="contacts-worktime-time">
                                    12:00 - 20:00
                                </div>
                            </div>

                        </div>

                        <div className="contacts-address">
                            <div className="contacts-address-title">
                                Наша адреса
                            </div>
                            <div className="contacts-address-value">
                                м. Київ, пр. Академіка Глушкова 1, пав. 27 <br /><span>(липова алея ВДНГ)</span>
                            </div>
                            <a
                                className="contacts-address-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://g.page/ecotrack"
                            >Переглянути на мапі</a>

                        </div>

                        <div className="contacts-digit">
                            <div className="contacts-digit-title">
                                Контакти
                            </div>
                            <a
                                href="tel:+380674029302"
                                className="contacts-digit-value"
                            >+38 (067) 402 93 02</a>
                            <a
                                href="tel:+380503812269"
                                className="contacts-digit-value"
                            >+38 (050) 381 22 69</a>
                            <a
                                href="tel:+380638683864"
                                className="contacts-digit-value"
                            >+38 (063) 868 38 64</a>
                            <div className="contacts-digit-value">
                                ecotrack.rc@gmail.com
                            </div>
                        </div>

                        {/* <div className="contacts-social">
                    <a
                        className="contacts-social-link"
                        target="_blank" 
                        href="https://www.facebook.com/ECOTrackRC/"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                        className="contacts-social-link"
                        target="_blank" 
                        href="https://www.instagram.com/ecotrack.rc/"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-instagram"></i>
                    </a>
                </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Contacts
