import React, { Component } from 'react'
import "../css/About.css"
import track from '../img/location_view.jpg'

export class About extends Component {


    state = {
        imageViewLoaded: false
    }



    render() {
        const { imageViewLoaded } = this.state
        return (
            <div className="About">
                <div className="container">
                    <h1 className="title">Про трек</h1>

                    <div className="track_wrap">

                        <div className="track__title">
                            <p>Eco Track - це єдина локація в Україні яка складається з декількох трас зі штучним газонним покриттям (астротурф). Траси призначені для off-road автомоделей різного масштабу (від 1:28 до 1:10).</p>
                            <p>Види достпуних трас:</p>
                            <ul>
                                <li>
                                    <p><strong>Little Track</strong> - це трек для пілотування наймолодшими пілотами (від 5 років) моделями 1:28 масштабу.</p>
                                </li>
                                <li>
                                    <p><strong>Junior Track</strong> - це трек початкового рівня для використання моделей масштабом до 1:10. Має просту конфігурацію, відсутність перешкод та розрахований для набуття навичок керування.</p>
                                </li>
                                <li>
                                    <p><strong>Profi Track</strong> - це трек для досвідчених пілотів. Має складну конфігурацію довжиною 250м, багато трамплінів, характерних перешкод та складних ділянок. Розрахований на використання моделей масштабом до 1:10.</p>
                                </li>
                                <li>
                                    <p><strong>Crawler Park</strong> - це трек з великою кількістю спеціальних характерних перешкод для трофі моделей масштабом 1:10.</p>
                                </li>
                            </ul>
                            <p>Локація розрахована на використання тільки електричних моделей. Спектр користувачів: від дітей до професійних спортсменів.</p>
                            <p>На території є <strong>Party Zone</strong> для проведення івентів різного формату.</p>
                        </div>
                    </div>
                </div>

                <div className="track__img">

                    <div className={imageViewLoaded ? "track__img_inner" : "track__img_inner-loading"}>

                        <div className="track_pin_wrap">
                            <div className="track_pin"></div>
                            <div className="track_pin_descr">
                                Junior Track - трек початкового рівня без перешкод для пілотів які не мають досвіду керування автомоделлю
                            </div>
                        </div>
                        <div className="track_pin_wrap">
                            <div className="track_pin"></div>
                            <div className="track_pin_descr">
                                Profi Track - професійний трек з технічними ділянками та перешкодами різної складності. Розрахований на пілотів які вже мають досвід керування автомоделлю
                            </div>
                        </div>
                        <div className="track_pin_wrap">
                            <div className="track_pin"></div>
                            <div className="track_pin_descr">
                                Party Zone - BBQ зона для проведення івентів
                            </div>
                        </div>
                        <div className="track_pin_wrap">
                            <div className="track_pin"></div>
                            <div className="track_pin_descr">
                                Little Track - траса для наймолодших пілотів з моделями масштабом 1/28
                            </div>
                        </div>
                        <div className="track_pin_wrap">
                            <div className="track_pin"></div>
                            <div className="track_pin_descr">
                                Майстерня та приміщення адміністратора
                            </div>
                        </div>
                        <div className="track_pin_wrap">
                            <div className="track_pin"></div>
                            <div className="track_pin_descr">
                                Зона кафе з власними фірмовими бургерами та напоями
                            </div>
                        </div>
                        <div className="track_pin_wrap">
                            <div className="track_pin"></div>
                            <div className="track_pin_descr">
                                Crawler Park - траса для трофі моделей масштабом 1/10
                            </div>
                        </div>
                        <div className="track_pin_wrap">
                            <div className="track_pin"></div>
                            <div className="track_pin_descr">
                                Піт-зона для пілотів з власними автомоделями
                            </div>
                        </div>
                        <img
                            onLoad={() => { this.setState({ imageViewLoaded: true }) }}
                            src={track}
                            alt="eco track"
                        />
                    </div>
                    <img
                        className={imageViewLoaded ? "preloader preloader-disable" : "preloader"}
                        src={require("../img/preloader.gif")}
                        alt=""
                    />
                </div>
            </div>
        )
    }
}

export default About
