import React, { Component } from 'react'
import '../css/Header.css'
import DelayLink from './DelayLink'

export class Header extends Component {

    state = {
        menuButtonColor: false,
        path: "",
        navActive: false
    }


    componentDidUpdate(prevProps) {
        if (this.props.path !== prevProps.path) {
            this.setState({path: this.props.path})
        }

        if(this.props.path === '/' || prevProps.path === '/'){
            window.addEventListener("scroll", this.checkForButtonColor)
        } else window.removeEventListener("scroll", this.checkForButtonColor)
    }

    componentDidMount(){
        this.checkForButtonColor()
    }

    checkForButtonColor = () => {
        let topBlock = document.querySelector('.home-top');


        if(topBlock !== null && topBlock.offsetTop + topBlock.clientHeight - 20 > window.scrollY){
            this.setState({menuButtonColor: true})
        } else {
            this.setState({menuButtonColor: false})
        }
    }
    navToggle = () => {
        this.setState({navActive: !this.state.navActive})
    }
    
    
  render() {
      const {menuButtonColor, navActive, path} = this.state
    return (
      <div className="Header">
        <div className="container">
            <button 
                className={
                    (path === '/' || navActive) && (menuButtonColor || path !== '/') ? "menu_button" : "menu_button menu_button-color"} 
                onClick={()=>{
                this.navToggle()
            }}>
                <i className={!navActive?"fas fa-bars":"fas fa-times"}></i>
            </button>

            <div 
                className={!navActive ? "menu": "menu menu-blur"}
            >
                <nav className={!navActive ? "navigation" : "navigation navigation-active"}>
                    <ul>
                        <li>
                            <DelayLink
                                to="/"
                                navToggle={()=>this.navToggle()}
                            >
                                Головна
                            </DelayLink>
                        </li>
                        <li>
                            <DelayLink
                                to="/about"
                                navToggle={()=>this.navToggle()}
                            >
                                Про трек
                            </DelayLink>
                        </li>
                        <li>
                            <DelayLink
                                to="/services"
                                navToggle={()=>this.navToggle()}
                            >
                                Послуги
                            </DelayLink>
                        </li>
                        <li>
                            <DelayLink
                                to="/weekdriver"
                                navToggle={()=>this.navToggle()}
                            >
                                WeekDriver
                            </DelayLink>
                        </li>
                        <li>
                            <DelayLink
                                to="/gallery"
                                navToggle={()=>this.navToggle()}
                            >
                                Галерея
                            </DelayLink>
                        </li>
                        <li>
                            <DelayLink
                                to="/contacts"
                                navToggle={()=>this.navToggle()}
                            >
                                Контакти
                            </DelayLink>
                        </li>
                    </ul>
                </nav>
            
            </div>
        </div>
      </div>
    )
  }
}

export default Header
