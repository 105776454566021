import React, { Component } from 'react'
import DelayLink from './DelayLink'
import EventImages from './EventImages'
import WrongPage from '../components/WrongPage'
import '../css/EventDetail.css'
import axios from 'axios'


export class EventDetail extends Component {

    state = {
        photoIndex: 0,
        isOpen: false,
        eventData: ''
    }

    componentDidMount(){


        const { event } = this.props.match.params

        axios.get(`${process.env.REACT_APP_API_PATH}/events/?slug=${event}`)
        .then((response) => {
            this.setState({eventData: response.data[0]})
        })
        .catch((error) => {
            console.log(error);
        })
    }

    formateDate = (date) => {
        date = new Date(date)
        let d = `${date.getDate()}`.length > 1 ? date.getDate() : `0${date.getDate()}`
        let m = `${date.getMonth()+1}`.length > 1 ? date.getMonth()+1 : `0${date.getMonth()+1}`
        let y = date.getFullYear()

        return (`${d}.${m}.${y}`)
    }


    render() {

        const { eventData } = this.state
        const { event_title, event_gallery, event_from_date, event_to_date } = this.state.eventData

        let imagesS = event_gallery ? event_gallery.map(i => {
            return process.env.REACT_APP_API_PATH + i.formats.thumbnail.url
        }) : ''
        let imagesB = event_gallery ? event_gallery.map(i => {
            if(i.formats.large){
                return process.env.REACT_APP_API_PATH + i.formats.large.url 
            } else {
                return process.env.REACT_APP_API_PATH + i.formats.medium.url 
            }
        }) : ''

        let date = event_from_date === event_to_date ? this.formateDate(event_from_date) : `${this.formateDate(event_from_date)} - ${this.formateDate(event_to_date)}`


        
        return (

            eventData !== '' ? (
                <div className="EventDetail">
                    <button className="back-btn">
                        <DelayLink to='/gallery'>
                            <i className="fas fa-angle-left"></i>
                            галерея
                        </DelayLink>
                    </button>
                    <div className="container">
                        
                        <div className="event">
                            <div className="event__title-row">
                                <div className="event__title">
                                    <div className="event__title__date">
                                        {date}
                                    </div>
                                    <h1 className="event__title__name">
                                        {event_title}
                                    </h1>
                                </div>
                                
                            </div>
                            <EventImages
                                images = {imagesB}
                                thumbs = {imagesS}
                                video = {eventData.video ? eventData.video : null}
                            />
                        </div>
                    </div>
                </div>
            ): <WrongPage />
            
            
            
        )
    }
}

export default EventDetail
