import React from 'react'
import '../../css/WeekDriver/RaceItem.css'
import place1 from '../../img/places/place1.png'
import place2 from '../../img/places/place2.png'
import place3 from '../../img/places/place3.png'

function PastRaces({ race }) {

    let formateDate = (date) => {
        date = new Date(date)
        let d = `${date.getDate()}`.length > 1 ? date.getDate() : `0${date.getDate()}`
        let m = `${date.getMonth() + 1}`.length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
        let y = date.getFullYear()

        return (`${d}.${m}.${y}`)
    }


    return (
        <div className="RaceItem">
            <div className="race-title">
                {race.race_name}
            </div>
            <div className="race-info">
                <div className="race-date">
                    {formateDate(race.race_date)}
                </div>

                {race.race_detailed_url ?
                    <a
                        href={race.race_detailed_url}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="race-detailed"
                    >
                        детальніше
                    </a>
                    : null}

            </div>

            {race.driver_name_1 && race.driver_name_1 && race.driver_name_1 ?

                <div className="race-places">
                    <div className="race-place">
                        <div className="race-place-position">
                            <img src={place1} alt="" />
                        </div>
                        <div className="race-place-driver">
                            <div className="driver-name">
                                {race.driver_name_1.split(" ")[0]}
                            </div>
                            <div className="driver-surname">
                                {race.driver_name_1.split(" ")[1]}
                            </div>
                        </div>
                    </div>
                    <div className="race-place">
                        <div className="race-place-position">
                            <img src={place2} alt="" />
                        </div>
                        <div className="race-place-driver">
                            <div className="driver-name">
                                {race.driver_name_2.split(" ")[0]}
                            </div>
                            <div className="driver-surname">
                                {race.driver_name_2.split(" ")[1]}
                            </div>
                        </div>
                    </div>
                    <div className="race-place">
                        <div className="race-place-position">
                            <img src={place3} alt="" />
                        </div>
                        <div className="race-place-driver">
                            <div className="driver-name">
                                {race.driver_name_3.split(" ")[0]}
                            </div>
                            <div className="driver-surname">
                                {race.driver_name_3.split(" ")[1]}
                            </div>
                        </div>
                    </div>
                </div>
                : null}

        </div>
    )
}

export default PastRaces
