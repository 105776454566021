import React, { Component } from 'react'
import '../../css/Services/ServiceCard.css'
import DelayLink from '../DelayLink'

export class ServiceCard extends Component {

    render() {

        const { name, link, icon, description } = this.props.service

        return (
            <div className="ServiceCard-main">
                <DelayLink to={link}>
                    <div className={`ServiceCard-wrap service-item-${icon}`}>
                        <div className="ServiceCard">
                            <div className="ServiceCard-title">
                                {name}
                            </div>
                            <div className="ServiceCard-icon">
                                <img src={require(`../../img/services/icons/image${icon}.png`)} alt="" />
                            </div>
                            <div className="ServiceCard-description">
                                {description}
                            </div>
                        </div>
                    </div>
                </DelayLink>
            </div>
        )
    }
}

export default ServiceCard
