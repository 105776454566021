import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

export class ScrollTop extends Component {

  state = {
    path: ""
  }

  componentDidMount(){
    this.setState({path: this.props.location.pathname})
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
      this.setState({path: this.props.location.pathname})
    }
  }
    
  render() {

    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        path: this.state.path
      });
    });

    
    return children
  
  }
}
    
export default withRouter(ScrollTop);