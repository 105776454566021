import React, {Component} from 'react'

const Context = React.createContext()

const reducer = (state, action) => {
    switch (action.type) {

        case 'SLIDER':
            return {
                ...state,
                slider: action.payload
            }

        case 'SPINNER':
            return {
                ...state,
                spinner: action.payload
            }

        default: return state
    }
}



export class Provider extends Component {
    
    state = {
        slider: true,
        spinner: true,

        dispatch: action => {
            this.setState(state => reducer(state, action))
        }
    }
    

    render(){
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        )
    }
    

}

export const Consumer = Context.Consumer