import React, { Component } from 'react'
import Affiche from './Affiche'
import '../css/Affiches.css'
import axios from 'axios'

export class Affiches extends Component {

    state = {
        AffichesToDisplay: []
    }

    getActualAffiches = (affiches, getScrollButtonInfo) => {
        let actualAffiches = []
        affiches.forEach(item=>{
            let now = new Date();
            let eventDate = new Date(item.date);
            const _ms_per_day = 1000 * 60 * 60 * 24;

            const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
            const utc2 = Date.UTC(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());

            let dayDelta = Math.floor((utc2 - utc1) / _ms_per_day);

            if (dayDelta >= 0){
                actualAffiches.push(item)
            }
        })

        actualAffiches.sort((a,b)=>{
            return new Date(a.date) - new Date(b.date);
        })

        this.setState({AffichesToDisplay: actualAffiches})

        if (actualAffiches.length !== 0){
            getScrollButtonInfo(true)
        }

    }

    componentDidMount(){

        let now = new Date(new Date().setHours(0,0,0,0)).toISOString()

        axios.get(`${process.env.REACT_APP_API_PATH}/affiches?_sort=from_date:asc&to_date_gte=${now}`)
        .then((response) => {

            if (response.data.length !== 0){
                this.props.getScrollButtonInfo(true)
            }

            this.setState({AffichesToDisplay: response.data})

        })
        .catch((error) => {
            console.log(error);
        })

    }

    render() {
        const { AffichesToDisplay } = this.state

        if(AffichesToDisplay.length !== 0){

            return (
                <div id="affiches-wrap">
                    <h2 className="title">Найближчі події</h2>
                    <div className="affiches-list">
                        <div className="container">
    
                            {AffichesToDisplay.map((item, index)=>{
                                return (
                                    <Affiche
                                        key={index}
                                        affiche={item}
                                        index={index}
                                    />
                                )
                            })}
    
                        </div>
                    </div>
                </div>
            )
        } else return null
    }
}

export default Affiches
