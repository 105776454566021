import React, { Component } from 'react'
import '../../css/Services/Rent.css'
import rent from '../../rent.json'
import RentImages from './RentImages'
import DelayLink from '../DelayLink'
import TopSectionServices from './TopSectionServices'



export class Rent extends Component {


    state = {
        track: 80,
        models: 2,
        obstacles: 0,
        days: 1,
        sale: 0,
        photoIndex: 0,
        isOpen: false
    }


    render() {
        const { sale, track, models, obstacles, days} = this.state

        const soundware = 3000
        const commentator = 1200
        const techguy = 500
        const logistic = 1500

        let estimationResult = Math.round(
            ((
            (25*track) + 
            (3000 + (1000 * models)) + 
            (2000 * obstacles)
            ) * days * 
            (1 - sale/100)) +
            ((soundware + commentator + techguy) * days) + 
            logistic
        )

        let imagesB = [];
        let imagesS = [];

        rent.forEach(item => {
            let arr = Array.from({length: item.cells}, (_,i)=>{
                return require(`../../img/rent/${item.folder}/b/img${i+1}.jpg`)
            })
            imagesB.push(arr)
        })
        rent.forEach(item => {
            let arr = Array.from({length: item.cells}, (_,i)=>{
                return require(`../../img/rent/${item.folder}/s/img${i+1}.jpg`)
            })
            imagesS.push(arr)
        })

        return (
            <div className="Rent">
                <button className="back-btn">
                    <DelayLink to='/services'>
                        <i className="fas fa-angle-left"></i>
                        <span>послуги</span>
                    </DelayLink>
                </button>
                <TopSectionServices img={'hero3.jpg'} title="Виїзний прокат" />

                <div className="container">
                    <div className="rent-about rent-section">
                        <h3 className="rent-subtitle">Що це?</h3>
                        <p className="rent-about-descr">Виїзний прокат - це мобільна траса для радіокерованих автомоделей з різними перешкодами, де автомоделі на немалій швидкості змагаються за лідерство. Це дуже гарний спосіб здивувати та привернути увагу глядача. Унікальніть такого інструменту має значні переваги. Це повністю новий продукт без аналогів на ринку.</p>
                    </div>
                    <div className="rent-for rent-section">
                        <h3 className="rent-subtitle">Де буде доцільно?</h3>
                        <div className="rent-for-row">
                            <div className="rent-for-row-mark"></div>
                            <p className="rent-for-row-item">Промо-акції</p>
                        </div>
                        <div className="rent-for-row">
                            <div className="rent-for-row-mark"></div>
                            <p className="rent-for-row-item">Тематичні івенти</p>
                        </div>
                        <div className="rent-for-row">
                            <div className="rent-for-row-mark"></div>
                            <p className="rent-for-row-item">Виставки</p>
                        </div>
                        <div className="rent-for-row">
                            <div className="rent-for-row-mark"></div>
                            <p className="rent-for-row-item">Корпоративи</p>
                        </div>
                        <div className="rent-for-row">
                            <div className="rent-for-row-mark"></div>
                            <p className="rent-for-row-item">Дні народження</p>
                        </div>
                    </div>

                    <div className="rent-min rent-section">
                        <h3 className="rent-subtitle">Мінімальний комплект виїзного прокату</h3>
                        <p className="rent-min-item">
                            <span>1.</span>Трек площею не менше 80 м<sup>2</sup>
                        </p>
                        <p className="rent-min-item">
                            <span>2.</span>Радіокеровані автомоделі 2шт.
                        </p>
                        <p className="rent-min-item">
                            <span>3.</span>Технік, який відповідальний за стан автомоделей та треку
                        </p>
                        <p className="rent-min-item">
                            <span>4.</span>Професійний суддя-коментатор для супроводження перегонів
                        </p>
                        <p className="rent-min-item">
                            <span>5.</span>Звукове обладнання для озвучення локації
                        </p>
                    </div>

                    <div className="rent-example rent-section">
                        <h3 className="rent-subtitle">Приклади виїзного прокату</h3>

                        {rent.map((item, index) => (
                            <div className="rent-example-item" key={index}>
                                <div className="rent-example-item__info">
                                    <div className="rent-example-item__info__name">
                                        {item.title}
                                    </div>
                                    {item.area ? 
                                        <div className="rent-example-item-li">
                                            <p><span>Площа, м<sup>2</sup>:</span>{item.area}</p>
                                        </div>
                                    :null}                                    
                                    {item.descr !== "" ?
                                    <div className="rent-example-item-li rent-example-item-descr">
                                        <p>{item.descr}</p>
                                    </div>
                                    :null}
                                </div>
                                <RentImages
                                    key = {index}
                                    images = {imagesB[index]}
                                    thumbs = {imagesS[index]}
                                    video = {item.video ? item.video : null}
                                />

                            </div>
                        ))}
                    </div>

                    <div className="rent-estimation rent-section">
                        <h3 className="rent-subtitle">
                            Калькулятор вартості виїзного прокату
                        </h3>

                        <form>
                            <div className="rent-estimation-row">
                                <div className="rent-estimation-row-title">
                                    Траса, м<sup>2</sup>
                                </div>
                                <div className="select-style">
                                    <select
                                        value={this.state.track}
                                        onChange={(e)=>{
                                            this.setState({track: e.target.value})
                                        }}
                                    >
                                        <option value={80}>80</option>
                                        <option value={100}>100</option>
                                        <option value={150}>150</option>
                                        <option value={200}>200</option>
                                        <option value={250}>250</option>
                                        <option value={300}>300</option>
                                    </select>
                                </div>
                            </div>
                            <div className="rent-estimation-row">
                                <div className="rent-estimation-row-title">
                                    Автомоделі, шт.
                                </div>
                                <div className="select-style">
                                    <select
                                        value={this.state.models}
                                        onChange={(e)=>{
                                            this.setState({models: e.target.value})
                                        }}
                                    >
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                    </select>
                                </div>
                            </div>
                            <div className="rent-estimation-row">
                                <div className="rent-estimation-row-title">
                                    Базові перешкоди
                                </div>
                                <div className="select-style">
                                    <select
                                        value={this.state.obstacles}
                                        onChange={(e)=>{
                                            this.setState({obstacles: e.target.value})
                                        }}
                                    >
                                        <option value={0}>Ні</option>
                                        <option value={1}>Так</option>
                                    </select>
                                </div>
                            </div>
                            <div className="rent-estimation-row">
                                <div className="rent-estimation-row-title">
                                    Кількість днів
                                </div>
                                <div className="select-style">
                                    <select
                                        value={this.state.days}
                                        onChange={(e)=>{
                                            this.setState({days: e.target.value})
                                            this.setState({sale: e.target.options[e.target.selectedIndex].dataset.sale})
                                        }}
                                    >
                                        <option value={1} data-sale={0}>1</option>
                                        <option value={2} data-sale={30}>2</option>
                                        <option value={3} data-sale={40}>3</option>
                                        <option value={4} data-sale={50}>4</option>
                                        <option value={5} data-sale={55}>5</option>
                                        <option value={6} data-sale={60}>6</option>
                                        <option value={7} data-sale={62.5}>7</option>
                                    </select>
                                </div>
                            </div>

                            <div className="rent-estimation-row">
                                <div className="rent-estimation-result">
                                 {`${estimationResult} грн`}
                                </div>
                            </div>

                            <div className="rent-estimation-row">
                                <p className="rent-estimation-comment">
                                    Включено: звукове обладнання, суддя-коментатор, технік та логістика (у межах Києва)
                                </p>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        )
    }
}

export default Rent
