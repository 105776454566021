import React, { Component } from 'react'
import '../css/Affiche.css'

export class Affiche extends Component {


    getMonth = (date) => {
        let months = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
        return months[new Date(date).getMonth()]
    }

    getDaysTo = date => {

        let now = new Date();
        let eventDate = new Date(date);
        const _ms_per_day = 1000 * 60 * 60 * 24;

        const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
        const utc2 = Date.UTC(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());

        let dayDelta = Math.floor((utc2 - utc1) / _ms_per_day);


        return dayDelta

    }



    render() {

        const { title, description, from_date, to_date, thumbnail, extra_info, free, entrance_fee } = this.props.affiche
        const { index } = this.props

        let eventDay = from_date === to_date ? new Date(from_date).getDate() : `${new Date(from_date).getDate()}-${new Date(to_date).getDate()}`

        const daysTo = this.getDaysTo(from_date)

        let thumb = thumbnail ? process.env.REACT_APP_API_PATH + thumbnail.formats.medium.url : null

        return (
            <div
                className="Affiche"
                data-aos="fade-zoom-in" 
                data-aos-delay={`${index * 200}`}
                data-aos-offset="100"
            >

                <div className="affiche-content">

                    <div className={thumb ? "affiche-date has-thumb" : "affiche-date"}>
                        <div className="affiche-date-day">{eventDay}</div>
                        <div className="affiche-date-month">{this.getMonth(from_date)}</div>
                        <div className={daysTo <= 1 ? "affiche-days-to affiche-soon" : "affiche-days-to"}>
                            {daysTo === 1 ? 'Подія завтра' : (daysTo <= 0 ? 'Подія сьогодні' : `Через днів: ${daysTo}`)}
                        </div>
                    </div>

                    <div className="affiche-main">
                        <div 
                            className="affiche-title"
                            data-aos="fade-zoom-in" 
                            data-aos-delay={`${(index * 200)+100}`}
                            data-aos-offset="20"
                        >{title}</div>
                        {description ? 
                            <div 
                                className="affiche-description"
                                data-aos="fade-zoom-in" 
                                data-aos-delay={`${(index * 200)+150}`}
                                data-aos-offset="20"

                            >{description}</div>
                        : null}

                        <div 
                            className="extra-info"
                            data-aos="fade-zoom-in" 
                            data-aos-delay={`${(index * 200)+150}`}
                            data-aos-offset="20"
                        >
                            {extra_info.length !== 0 ? extra_info.map((row, index)=>{

                                let isLink = row.value.slice(0, 4) === 'http'
                                return (
                                    <div key={index} className="extra-info-row">
                                        <div className="row-label">
                                            {row.label}
                                        </div>
                                        <div className="row-value">
                                            {isLink ? 
                                                <a target="_blank" rel="noopener noreferrer" href={row.value}>посилання</a>
                                            :
                                                row.value
                                            }
                                        </div>
                                    </div>
                                )
                            }) : null}

                            <div key={index} className="extra-info-row extra-info-row-fee">
                                <div className="row-label">
                                    {free ? 'Вхід вільний' : 'Вартість:'}
                                </div>
                                {!free ? 
                                    <div className="row-value">
                                        {`${entrance_fee}₴`}
                                    </div>
                                :null}
                            </div>
                        </div>
                    </div>

                    {thumb ? 
                        <div className="affiche-thumb">
                            <img src={thumb} alt=""/>
                        </div>
                    :null}
                </div>
            </div>
        )
    }
}

export default Affiche
