import React, { Component } from 'react'
import '../../css/Services/Price.css'
import TopSectionServices from './TopSectionServices'
import DelayLink from '../DelayLink'


export class Price extends Component {

    render() {

        return (
            <div className="Price">

                <button className="back-btn">
                    <DelayLink to='/services'>
                        <i className="fas fa-angle-left"></i>
                        <span>послуги</span>
                    </DelayLink>

                </button>
                <TopSectionServices img={'hero1.jpg'} title="Тарифи на прокат автомоделей" />


                <div className="container">

                    <div className="price-table-wrap">
                        <div className="row row-head">
                            <div className="cell cell-title"></div>
                            {daysRow()}
                        </div>
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/price/price-img-1.jpg')} alt="" />
                                <p>Little трек</p>
                                <div className="duration">1 заїзд - 10 хвилин</div>
                            </div>
                            {daysRow()}
                            <div className="cell cell-price cell-price-value gray">100 <span>₴</span></div>
                            <div className="cell cell-price cell-price-value gray">150 <span>₴</span></div>
                        </div>
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/price/price-img-2.jpg')} alt="" />
                                <p>Junior & PRO треки</p>
                                <div className="duration">1 заїзд - 10 хвилин</div>
                            </div>
                            {daysRow()}
                            <div className="cell cell-price cell-price-value gray">200 <span>₴</span></div>
                            <div className="cell cell-price cell-price-value gray">250 <span>₴</span></div>
                        </div>
                        <div className="row">
                            <div className="cell cell-title gradient">
                                <img src={require('../../img/price/price-img-3.jpg')} alt="" />
                                <p>Crawler Park</p>
                                <div className="duration">1 заїзд - 20 хвилин</div>
                            </div>
                            {daysRow()}
                            <div className="cell cell-price cell-price-value gray">400 <span>₴</span></div>
                            <div className="cell cell-price cell-price-value gray">500 <span>₴</span></div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

function daysRow() {
    return (
        <>
            <div className="cell cell-price cell-price-head yellow">Будні</div>
            <div className="cell cell-price cell-price-head green">Вихідні</div>
        </>
    )
}

export default Price
